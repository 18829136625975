var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-wrap" },
    [
      [
        _vm.memberCode === "mubai" || _vm.memberCode === "bbelc"
          ? _c(
              "div",
              { staticClass: "all-lines" },
              [
                _c(
                  "div",
                  { staticClass: "checkAll" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { indeterminate: _vm.isIndeterminate },
                        on: { change: _vm.selectCheck },
                        model: {
                          value: _vm.checkeds,
                          callback: function($$v) {
                            _vm.checkeds = $$v
                          },
                          expression: "checkeds"
                        }
                      },
                      [_vm._v("全选")]
                    )
                  ],
                  1
                ),
                _vm._l(_vm.lineTags, function(tag, index) {
                  return _c(
                    "div",
                    { key: tag.id, staticClass: "flex-cc tag" },
                    [
                      _c("div", [_vm._v(_vm._s(tag.name))]),
                      _c("el-button", {
                        staticStyle: {
                          "font-size": "16px",
                          color: "#00000051"
                        },
                        attrs: { type: "text", icon: "el-icon-error" },
                        on: {
                          click: function($event) {
                            return _vm.delTag(tag, index)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                _c("el-button", {
                  staticStyle: {
                    "font-size": "20px",
                    padding: "0",
                    "margin-top": "-10px"
                  },
                  attrs: {
                    disabled: _vm.checkeds,
                    type: "text",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: { click: _vm.addTag }
                })
              ],
              2
            )
          : _vm._e(),
        _vm.memberCode !== "bbelc"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "container-top common-pad"
              },
              [
                _c("div", { staticClass: "order-left" }, [
                  _c("div", { staticClass: "left-tit" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("进行中订单数")
                    ]),
                    _c("div", { staticClass: "des" }, [
                      _vm._v(_vm._s(_vm.orderInfo.doingCount))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "left-cen" },
                    _vm._l(_vm.allStatus, function(item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "cen-li" },
                        [
                          _c("div", {
                            staticClass: "tip",
                            style: { background: item.color }
                          }),
                          _c("div", { staticClass: "tit" }, [
                            _vm._v(_vm._s(item.name) + "：")
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "desc",
                              style: { color: item.color }
                            },
                            [_vm._v(_vm._s(item.count))]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "left-bot" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tip",
                        style: {
                          width: _vm.fixData(_vm.allStatus[0].count) + "%"
                        }
                      },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.fixData(_vm.allStatus[0].count)) + "%"
                          )
                        ]),
                        _c("span")
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tit",
                        style: {
                          width: _vm.fixData(_vm.allStatus[1].count) + "%"
                        }
                      },
                      [
                        _c("div"),
                        _c("span"),
                        _vm._v(
                          " " +
                            _vm._s(_vm.fixData(_vm.allStatus[1].count)) +
                            "% "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "desc",
                        style: {
                          width: _vm.fixData(_vm.allStatus[2].count) + "%"
                        }
                      },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.fixData(_vm.allStatus[2].count)) + "%"
                          )
                        ]),
                        _c("span")
                      ]
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    staticClass: "order-cen"
                  },
                  [
                    _c("div", { staticClass: "tit" }, [_vm._v("订单到期情况")]),
                    _c("ve-pie", {
                      staticStyle: { margin: "0 auto" },
                      attrs: {
                        data: _vm.chartDataOrder,
                        extend: _vm.pieChartExtendOrder,
                        width: "100%",
                        height: "230px"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "order-right" },
                  [
                    _c("div", { staticClass: "right-tit" }, [
                      _vm._v("完成订单数量")
                    ]),
                    _vm._l(_vm.allOrderNums, function(item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "right-bot" },
                        [
                          _c("div", { staticClass: "tip" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("div", { staticClass: "des" }, [
                            _vm._v(_vm._s(item.count))
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "container-center common-pad" }, [
          _c("div", { staticClass: "work-left" }, [
            _c(
              "div",
              { staticClass: "common-tit" },
              [
                _c("div", { staticClass: "tit" }, [_vm._v("生产数量统计")]),
                _c(
                  "el-radio-group",
                  {
                    staticClass: "m-radio-group m-radio-groups",
                    on: {
                      change: function($event) {
                        return _vm.radioChange(0)
                      }
                    },
                    model: {
                      value: _vm.params.radio[0],
                      callback: function($$v) {
                        _vm.$set(_vm.params.radio, 0, $$v)
                      },
                      expression: "params.radio[0]"
                    }
                  },
                  _vm._l(_vm.allDays, function(item) {
                    return _c(
                      "el-radio-button",
                      { key: item.id, attrs: { label: item.label } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "left-bot" }, [
              _c("div", { staticClass: "bot-li" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("生产数量")]),
                _c("div", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.productInfo.finishedCount))
                ])
              ]),
              _c("div", { staticClass: "bot-li" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("计划产量")]),
                _c("div", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.productInfo.planCount))
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "bot-li",
                  staticStyle: {
                    background: "rgba(0, 171, 41, 0.1)",
                    "margin-right": "0"
                  }
                },
                [
                  _c("img", {
                    staticClass: "tip",
                    attrs: { src: require("@/assets/home/inspect.png") }
                  }),
                  _c("div", { staticClass: "tit" }, [_vm._v("达成率")]),
                  _c(
                    "div",
                    { staticClass: "count", staticStyle: { color: "#00B42E" } },
                    [_vm._v(_vm._s(_vm.productInfo.finishedRatio) + "%")]
                  )
                ]
              ),
              _c("div", { staticClass: "bot-li" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("检验数量")]),
                _c("div", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.productInfo.checkCount))
                ])
              ]),
              _c("div", { staticClass: "bot-li" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("不合格数")]),
                _c("div", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.productInfo.ngCount))
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "bot-li",
                  staticStyle: {
                    background: "rgba(224, 32, 32, 0.1)",
                    "margin-right": "0"
                  }
                },
                [
                  _c("img", {
                    staticClass: "tip",
                    attrs: { src: require("@/assets/home/no-inspect.png") }
                  }),
                  _c("div", { staticClass: "tit" }, [_vm._v("不合格率")]),
                  _c(
                    "div",
                    { staticClass: "count", staticStyle: { color: "#E02020" } },
                    [_vm._v(_vm._s(_vm.productInfo.ngRatio) + "%")]
                  )
                ]
              )
            ])
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "work-center"
            },
            [
              _c("div", { staticClass: "tit" }, [_vm._v("工单状态统计")]),
              _c("ve-pie", {
                staticStyle: { margin: "0 auto" },
                attrs: {
                  data: _vm.chartDataWorkStatus,
                  extend: _vm.pieChartExtendWorkStatus,
                  width: "100%",
                  height: "230px"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "work-right"
            },
            [
              _c("div", { staticClass: "tit" }, [_vm._v("工单到期情况")]),
              _c("ve-pie", {
                staticStyle: { margin: "0 auto" },
                attrs: {
                  data: _vm.chartDataWorkOrder,
                  extend: _vm.pieChartExtendOrder,
                  width: "100%",
                  height: "230px"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "container-bottom common-pad" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "product-left"
            },
            [
              _c("div", { staticClass: "all-title" }, [_vm._v("今日生产任务")]),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  data: _vm.list,
                  height: 350,
                  columns: _vm.columnsAll,
                  "columns-setting": false,
                  "show-page": false
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "product-right" },
            [
              _c("div", { staticClass: "tit" }, [
                _vm._v("近7日完工数量趋势图")
              ]),
              _c("ve-line", {
                staticStyle: { margin: "0 auto" },
                attrs: {
                  data: _vm.chartDataLine,
                  extend: _vm.lineChartExtend,
                  width: "100%",
                  height: "350px"
                }
              })
            ],
            1
          )
        ]),
        _vm.memberCode !== "bbelc"
          ? _c("div", { staticClass: "container-footer common-pad" }, [
              _vm.chartData.columns.length
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      staticClass: "inspection-left"
                    },
                    [
                      _c("div", { staticClass: "tit" }, [_vm._v("质检任务数")]),
                      _c("ve-pie", {
                        staticStyle: { margin: "0 auto" },
                        attrs: {
                          data: _vm.chartData,
                          settings: _vm.pieChartSettings,
                          extend: _vm.pieChartExtend,
                          width: "100%",
                          height: "230px"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "inspection-center" }, [
                _c(
                  "div",
                  { staticClass: "common-tit" },
                  [
                    _c("div", { staticClass: "tit" }, [_vm._v("首检数量统计")]),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "m-radio-group m-radio-groups",
                        on: {
                          change: function($event) {
                            return _vm.radioChange(1)
                          }
                        },
                        model: {
                          value: _vm.params.radio[1],
                          callback: function($$v) {
                            _vm.$set(_vm.params.radio, 1, $$v)
                          },
                          expression: "params.radio[1]"
                        }
                      },
                      _vm._l(_vm.allDays, function(item) {
                        return _c(
                          "el-radio-button",
                          { key: item.id, attrs: { label: item.label } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "left-bot" }, [
                  _c("div", { staticClass: "bot-li" }, [
                    _c("div", { staticClass: "tit" }, [_vm._v("检验数")]),
                    _c("div", { staticClass: "count" }, [
                      _vm._v(_vm._s(_vm.inspectInfo.checkCount))
                    ])
                  ]),
                  _c("div", { staticClass: "bot-li" }, [
                    _c("div", { staticClass: "tit" }, [_vm._v("合格数")]),
                    _c("div", { staticClass: "count" }, [
                      _vm._v(_vm._s(_vm.inspectInfo.okCount))
                    ])
                  ]),
                  _c("div", { staticClass: "bot-li" }, [
                    _c("div", { staticClass: "tit" }, [_vm._v("不合格数")]),
                    _c("div", { staticClass: "count" }, [
                      _vm._v(_vm._s(_vm.inspectInfo.ngCount))
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "bot-li",
                      staticStyle: { background: "rgba(0, 171, 41, 0.1)" }
                    },
                    [
                      _c("img", {
                        staticClass: "tip",
                        attrs: { src: require("@/assets/home/inspect.png") }
                      }),
                      _c("div", { staticClass: "tit" }, [_vm._v("合格率")]),
                      _c(
                        "div",
                        {
                          staticClass: "count",
                          staticStyle: { color: "#00B42E" }
                        },
                        [_vm._v(_vm._s(_vm.inspectInfo.okRatio) + "%")]
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "inspection-right" }, [
                _c(
                  "div",
                  { staticClass: "common-tit" },
                  [
                    _c("div", { staticClass: "tit" }, [
                      _vm._v("过程检数量统计")
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "m-radio-group m-radio-groups",
                        on: {
                          change: function($event) {
                            return _vm.radioChange(2)
                          }
                        },
                        model: {
                          value: _vm.params.radio[2],
                          callback: function($$v) {
                            _vm.$set(_vm.params.radio, 2, $$v)
                          },
                          expression: "params.radio[2]"
                        }
                      },
                      _vm._l(_vm.allDays, function(item) {
                        return _c(
                          "el-radio-button",
                          { key: item.id, attrs: { label: item.label } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "left-bot" }, [
                  _c("div", { staticClass: "bot-li" }, [
                    _c("div", { staticClass: "tit" }, [_vm._v("检验数")]),
                    _c("div", { staticClass: "count" }, [
                      _vm._v(_vm._s(_vm.inspectInfos.checkCount))
                    ])
                  ]),
                  _c("div", { staticClass: "bot-li" }, [
                    _c("div", { staticClass: "tit" }, [_vm._v("合格数")]),
                    _c("div", { staticClass: "count" }, [
                      _vm._v(_vm._s(_vm.inspectInfos.okCount))
                    ])
                  ]),
                  _c("div", { staticClass: "bot-li" }, [
                    _c("div", { staticClass: "tit" }, [_vm._v("不合格数")]),
                    _c("div", { staticClass: "count" }, [
                      _vm._v(_vm._s(_vm.inspectInfos.ngCount))
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "bot-li",
                      staticStyle: { background: "rgba(0, 171, 41, 0.1)" }
                    },
                    [
                      _c("img", {
                        staticClass: "tip",
                        attrs: { src: require("@/assets/home/inspect.png") }
                      }),
                      _c("div", { staticClass: "tit" }, [_vm._v("合格率")]),
                      _c(
                        "div",
                        {
                          staticClass: "count",
                          staticStyle: { color: "#00B42E" }
                        },
                        [_vm._v(_vm._s(_vm.inspectInfos.okRatio) + "%")]
                      )
                    ]
                  )
                ])
              ])
            ])
          : _vm._e(),
        _c(
          "MDialog",
          {
            attrs: { title: "添加" },
            on: { onOk: _vm.submitItem },
            model: {
              value: _vm.showLine,
              callback: function($$v) {
                _vm.showLine = $$v
              },
              expression: "showLine"
            }
          },
          [
            _c("MTable", {
              ref: "mTable",
              attrs: {
                columns: _vm.columns,
                height: _vm.height,
                data: _vm.lineItemList,
                "show-page": false,
                "columns-setting": false
              },
              on: { "selection-change": _vm.inspectionItemChange },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                }
              ])
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }