<template>
  <div class="home-wrap">
    <template>
      <div v-if="memberCode === 'mubai' || memberCode === 'bbelc'" class="all-lines">
        <div class="checkAll">
          <el-checkbox v-model="checkeds" :indeterminate="isIndeterminate" @change="selectCheck">全选</el-checkbox>
        </div>
        <div
          v-for="(tag, index) in lineTags"
          :key="tag.id"
          class="flex-cc tag"
        >
          <div>{{ tag.name }}</div>
          <el-button
            type="text"
            icon="el-icon-error"
            style="font-size: 16px;color: #00000051"
            @click="delTag(tag, index)"
          />
        </div>
        <el-button
          :disabled="checkeds"
          type="text"
          icon="el-icon-circle-plus-outline"
          style="font-size: 20px;padding:0;margin-top: -10px;"
          @click="addTag"
        />
      </div>
      <div v-loading="loading" class="container-top common-pad" v-if="memberCode !== 'bbelc'">
        <div class="order-left">
          <div class="left-tit">
            <div class="title">进行中订单数</div>
            <div class="des">{{ orderInfo.doingCount }}</div>
          </div>
          <div class="left-cen">
            <div v-for="item in allStatus" :key="item.id" class="cen-li">
              <div class="tip" :style="{ background: item.color}" />
              <div class="tit">{{ item.name }}：</div>
              <div class="desc" :style="{ color: item.color}">{{ item.count }}</div>
            </div>
          </div>
          <div class="left-bot">
            <div class="tip" :style="{width: fixData(allStatus[0].count) + '%' }">
              <div>{{ fixData(allStatus[0].count) }}%</div>
              <span />
            </div>

            <div class="tit" :style="{width: fixData(allStatus[1].count) + '%' }">
              <div />
              <span />
              {{ fixData(allStatus[1].count) }}%
            </div>
            <div class="desc" :style="{width: fixData(allStatus[2].count) + '%' }">
              <div>{{ fixData(allStatus[2].count) }}%</div>
              <span />
            </div>
          </div>
        </div>
        <div v-loading="loading" class="order-cen">
          <div class="tit">订单到期情况</div>
          <ve-pie
            :data="chartDataOrder"
            :extend="pieChartExtendOrder"
            width="100%"
            height="230px"
            style="margin: 0 auto"
          />
        </div>
        <div class="order-right">
          <div class="right-tit">完成订单数量</div>
          <div v-for="item in allOrderNums" :key="item.id" class="right-bot">
            <div class="tip">{{ item.name }}</div>
            <div class="des">{{ item.count }}</div>
          </div>
        </div>
      </div>
      <div class="container-center common-pad">
        <div class="work-left">
          <div class="common-tit">
            <div class="tit">生产数量统计</div>
            <el-radio-group v-model="params.radio[0]" class="m-radio-group m-radio-groups" @change="radioChange(0)">
              <el-radio-button v-for="item in allDays" :key="item.id" :label="item.label">{{ item.name }}</el-radio-button>
            </el-radio-group>
          </div>
          <div class="left-bot">
            <div class="bot-li">
              <div class="tit">生产数量</div>
              <div class="count">{{ productInfo.finishedCount }}</div>
            </div>
            <div class="bot-li">
              <div class="tit">计划产量</div>
              <div class="count">{{ productInfo.planCount }}</div>
            </div>
            <div class="bot-li" style="background: rgba(0, 171, 41, 0.1);margin-right: 0">
              <img src="@/assets/home/inspect.png" class="tip">
              <div class="tit">达成率</div>
              <div class="count" style="color: #00B42E;">{{ productInfo.finishedRatio }}%</div>
            </div>
            <div class="bot-li">
              <div class="tit">检验数量</div>
              <div class="count">{{ productInfo.checkCount }}</div>
            </div>
            <div class="bot-li">
              <div class="tit">不合格数</div>
              <div class="count">{{ productInfo.ngCount }}</div>
            </div>
            <div class="bot-li" style="background: rgba(224, 32, 32, 0.1);margin-right: 0">
              <img src="@/assets/home/no-inspect.png" class="tip">
              <div class="tit">不合格率</div>
              <div class="count" style="color: #E02020;">{{ productInfo.ngRatio }}%</div>
            </div>
          </div>
        </div>
        <div v-loading="loading" class="work-center">
          <div class="tit">工单状态统计</div>
          <ve-pie
            :data="chartDataWorkStatus"
            :extend="pieChartExtendWorkStatus"
            width="100%"
            height="230px"
            style="margin: 0 auto"
          />
        </div>
        <div v-loading="loading" class="work-right">
          <div class="tit">工单到期情况</div>
          <ve-pie
            :data="chartDataWorkOrder"
            :extend="pieChartExtendOrder"
            width="100%"
            height="230px"
            style="margin: 0 auto"
          />
        </div>
      </div>
      <div class="container-bottom common-pad">
        <div v-loading="loading" class="product-left">
          <div class="all-title">今日生产任务</div>
          <MTable ref="mTable" :data="list" :height="350" :columns="columnsAll" :columns-setting="false" :show-page="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          </MTable>
        </div>
        <div class="product-right">
          <div class="tit">近7日完工数量趋势图</div>
          <ve-line
            :data="chartDataLine"
            :extend="lineChartExtend"
            width="100%"
            height="350px"
            style="margin: 0 auto"
          />
        </div>
      </div>
      <div v-if="memberCode !== 'bbelc'" class="container-footer common-pad">
        <div v-if="chartData.columns.length" v-loading="loading" class="inspection-left">
          <div class="tit">质检任务数</div>
          <ve-pie
            :data="chartData"
            :settings="pieChartSettings"
            :extend="pieChartExtend"
            width="100%"
            height="230px"
            style="margin: 0 auto"
          />
        </div>
        <div class="inspection-center">
          <div class="common-tit">
            <div class="tit">首检数量统计</div>
            <el-radio-group v-model="params.radio[1]" class="m-radio-group m-radio-groups" @change="radioChange(1)">
              <el-radio-button v-for="item in allDays" :key="item.id" :label="item.label">{{ item.name }}</el-radio-button>
            </el-radio-group>
          </div>
          <div class="left-bot">
            <div class="bot-li">
              <div class="tit">检验数</div>
              <div class="count">{{ inspectInfo.checkCount }}</div>
            </div>
            <div class="bot-li">
              <div class="tit">合格数</div>
              <div class="count">{{ inspectInfo.okCount }}</div>
            </div>
            <div class="bot-li">
              <div class="tit">不合格数</div>
              <div class="count">{{ inspectInfo.ngCount }}</div>
            </div>
            <div class="bot-li" style="background: rgba(0, 171, 41, 0.1);">
              <img src="@/assets/home/inspect.png" class="tip">
              <div class="tit">合格率</div>
              <div class="count" style="color: #00B42E;">{{ inspectInfo.okRatio }}%</div>
            </div>
          </div>
        </div>
        <div class="inspection-right">
          <div class="common-tit">
            <div class="tit">过程检数量统计</div>
            <el-radio-group v-model="params.radio[2]" class="m-radio-group m-radio-groups" @change="radioChange(2)">
              <el-radio-button v-for="item in allDays" :key="item.id" :label="item.label">{{ item.name }}</el-radio-button>
            </el-radio-group>
          </div>
          <div class="left-bot">
            <div class="bot-li">
              <div class="tit">检验数</div>
              <div class="count">{{ inspectInfos.checkCount }}</div>
            </div>
            <div class="bot-li">
              <div class="tit">合格数</div>
              <div class="count">{{ inspectInfos.okCount }}</div>
            </div>
            <div class="bot-li">
              <div class="tit">不合格数</div>
              <div class="count">{{ inspectInfos.ngCount }}</div>
            </div>
            <div class="bot-li" style="background: rgba(0, 171, 41, 0.1);">
              <img src="@/assets/home/inspect.png" class="tip">
              <div class="tit">合格率</div>
              <div class="count" style="color: #00B42E;">{{ inspectInfos.okRatio }}%</div>
            </div>
          </div>
        </div>
      </div>
      <MDialog v-model="showLine" title="添加" @onOk="submitItem">
        <MTable
          ref="mTable"
          :columns="columns"
          :height="height"
          :data="lineItemList"
          :show-page="false"
          :columns-setting="false"
          @selection-change="inspectionItemChange"
        >
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        </MTable>
      </MDialog>
    </template>
  </div>
</template>

<script>
  import { columnsAll } from './columns'
  import { getUUid } from '@/utils'
  import { getToken, setToken } from '@/utils/auth'
  import dayjs from 'dayjs'
  import api from '@/api/production/work-order'
  import Hapi from '@/api/home'
  import processApi from '@/api/information/production/process'
  import { Encrypt } from '@/utils/sercet'
  import 'echarts/lib/component/legendScroll'
  const colorList = ['#7760FF', '#60E8FF', '#38E300', '#F7B500', '#FA6400', '#AF0F0F',
    '#B814D2', '#CB843B', '#C8CF0B', '#13B1C4', '#009112', '#BCBB95', '#FF84A1',
    '#808080', '#C89595', '#44D7B6', '#095C92', '#FFE1A5', '#0091FF', '#90BD61',
    '#95B2C8', '#AB6FA7', '#8A6161', '#EC67FF']
  const colorListOrder = ['#FA6400', '#607FFF']
  const colorListWorkStatus = ['#A4B3C6', '#91CC75']
  export default {
    name: 'Home',
    // beforeRouteEnter(to, from, next) {
    //   next(vm => {
    //     const token = getToken()
    //     const queryToken = to.query ? to.query.token : ''
    //     if (queryToken && queryToken !== token) {
    //       vm.isToken = false
    //       vm.getUserInfos(queryToken)
    //     } else {
    //       vm.isToken = true
    //       vm.$router.push({ path: 'home' })
    //     }
    //   })
    // },
    data() {
      return {
        isToken: false,
        lineItemLists: [],
        lineItemList: [],
        selectInspectionItems: [],
        columns: [
          {
            type: 'selection',
            width: 60,
            fixed: 'left'
          },
          {
            prop: 'index',
            label: '序号',
            slotName: 'index',
            hiddenSearch: true,
            sortable: false,
            width: 80
          },
          {
            prop: 'name',
            label: '产线',
            hiddenSearch: true,
            sortable: false
          }
        ],
        isIndeterminate: true,
        showLine: false,
        currentIdxs: [],
        checkeds: false,
        lineTags: [],
        loading: false,
        columnsAll,
        orderInfo: {},
        productInfo: {},
        inspectInfos: {},
        inspectInfo: {},
        params: {
          radio: ['last', 'last', 'last']
        },
        list: [],
        allStatus: [
          {
            id: 0,
            name: '未计划',
            color: '#F7B500',
            count: 0
          },
          {
            id: 3,
            name: '部分计划',
            color: '#607FFF',
            count: 0
          },
          {
            id: 1,
            name: '全部计划未完工',
            color: '#32C5FF',
            count: 0
          }
        ],
        allOrderNums: [
          {
            id: 0,
            name: '本日',
            count: 0
          },
          {
            id: 1,
            name: '本周',
            count: 0
          },
          {
            id: 2,
            name: '本月',
            count: 0
          },
          {
            id: 3,
            name: '本季度',
            count: 0
          },
          {
            id: 4,
            name: '本年',
            count: 0
          }
        ],
        allDays: [
          {
            id: 0,
            name: '昨日',
            label: 'last'
          },
          {
            id: 1,
            name: '本周',
            label: 'week'
          },
          {
            id: 2,
            name: '本月',
            label: 'month'
          },
          {
            id: 3,
            name: '本年',
            label: 'year'
          }
        ],
        allDaysJinshi: [
          {
            id: 1,
            name: '本周',
            label: 'week'
          },
          {
            id: 2,
            name: '本月',
            label: 'month'
          },
          {
            id: 3,
            name: '本年',
            label: 'year'
          }
        ],
        startEndDate: {
          beginTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
          endTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        },
        sevenStartEndDate: {
          beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
          endTime: dayjs().format('YYYY-MM-DD')
        },
        chartData: {
          columns: [],
          rows: []
        },
        chartDataOrder: {
          columns: [],
          rows: []
        },
        chartDataWorkStatus: {
          columns: [],
          rows: []
        },
        chartDataWorkOrder: {
          columns: [],
          rows: []
        },
        chartDataLine: {
          columns: [],
          rows: []
        },
        pieChartExtendWorkStatus: {
          color: colorListWorkStatus,
          legend: {
            show: true,
            type: 'scroll',
            orient: 'vertical',
            left: 10,
            textStyle: {
              width: 10,
              overflow: 'break'
            }
          },
          series: {
            radius: [0, '60%'],
            center: ['50%', '50%'],
            label: {
              width: 50,
              overflow: 'break'
            }
          },
          tooltip: {
            trigger: 'item'
            // appendToBody: true
          }
        },
        lineChartExtend: {
          color: colorList,
          legend: {
            type: 'scroll'
          },
          xAxis: {
            // boundaryGap: false,
            // splitLine: {
            //   show: true,
            //   lineStyle: {
            //     type: 'dashed',
            //     color: '#9597AE'
            //   }
            // }
          },
          yAxis: {
            // splitLine: {
            //   show: true,
            //   lineStyle: {
            //     type: 'dashed',
            //     color: '#9597AE'
            //   }
            // }
          },
          tooltip: {
            trigger: 'axis'
            // appendToBody: true
          }
        },
        pieChartExtendOrder: {
          color: colorListOrder,
          legendLimit: 10,
          legend: {
            show: true,
            type: 'scroll',
            orient: 'vertical',
            left: 0,
            textStyle: {
              width: 10,
              overflow: 'break'
            }
          },
          series: {
            radius: [0, '60%'],
            center: ['50%', '60%'],
            label: {
              width: 50,
              overflow: 'break'
            }
          },
          tooltip: {
            trigger: 'item'
            // appendToBody: true
          }
        },
        pieChartSettings: {},
        pieChartExtend: {
          color: colorList,
          legend: {
            show: true,
            type: 'scroll',
            orient: 'vertical',
            left: 10,
            textStyle: {
              width: 10,
              overflow: 'break'
            }
          },
          series: {
            radius: [0, '60%'],
            center: ['50%', '50%'],
            label: {
              width: 50,
              overflow: 'break'
            }
          },
          tooltip: {
            trigger: 'item'
            // appendToBody: true
          }
        }
      }
    },
    computed: {
      memberCode() {
        return sessionStorage.getItem('memberCode')
      },
      height() {
        return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70
      }
    },
    watch: {
      lineTags(val) {
        if (val.length > 0) {
          this.isIndeterminate = true
        } else {
          this.isIndeterminate = false
        }
      }
    },
    async mounted() {
      this.getOrder()
      this.allDays = this.memberCode === 'jinshi' ? this.allDaysJinshi : this.allDays
      this.params.radio = this.memberCode === 'jinshi' ? ['week', 'week', 'week'] : ['last', 'last', 'last']

      this.startEndDate = this.memberCode === 'jinshi'
        ? dayjs().day() === 0 ? {
            beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
            endTime: dayjs().format('YYYY-MM-DD')
          }
          : {
            beginTime: dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD'),
            endTime: dayjs().endOf('week').add(1, 'day').format('YYYY-MM-DD')
          }
        : {
          beginTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
          endTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        }
      if (this.memberCode !== 'bbelc') {
        this.getInspectionNum()
        this.getAllInspectNums(0)
        this.getAllInspectNums(1)
      }
      if (this.memberCode === 'bbelc' || this.memberCode === 'mubai') {
        await this.getAllLines()
        this.getAreaList()
      }
      this.getAlls()
    },
    methods: {
      getAlls() {
        this.getWorkOrderStatus()
        this.getProductionNum()
        this.getTableData()
        this.getFinishNums()
      },
      async getAllLines() {
        const res = await Hapi.getAllLines()
        if (res) {
          this.lineTags = res
        }
      },
      inspectionItemChange(val) {
        this.selectInspectionItems = val
      },
      // 获取产线
      async getAreaList() {
        const search = [
          {
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 2,
            operator: 'eq'
          },
          {
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }
        ]
        const res = await processApi.getAreaList({
          searchVal: Encrypt(JSON.stringify(search))
        })
        if (res) {
          this.lineItemLists = res
        }
      },
      async submitItem(done) {
        done()
        if (this.selectInspectionItems.length > 0) {
          const list = this.selectInspectionItems.map(item => {
            return {
              id: item.id,
              name: item.name
            }
          })
          const data = [...this.lineTags, ...list]
          const res = await Hapi.modifyLines({
            id: JSON.parse(sessionStorage.getItem('userInfo')).id,
            productionLineIdList: data.map(item => item.id)
          })
          if (res) {
            await this.getAllLines()
            this.getAlls()
            this.showLine = false
          }
        } else {
          this.$message.error('请您至少添加一条数据')
        }
      },
      async delTag(tag, index) {
        const data = this.lineTags.filter(item => item.id !== tag.id)
        const res = await Hapi.modifyLines({
          id: JSON.parse(sessionStorage.getItem('userInfo')).id,
          productionLineIdList: data.map(item => item.id)
        })
        if (res) {
          await this.getAllLines()
          this.getAlls()
        }
      },
      addTag() {
        if (this.lineTags.length > 0) {
          const lines = this.lineTags.map(item => item.id)
          this.lineItemList = this.lineItemLists.filter(item => !lines.includes(item.id))
        } else {
          this.lineItemList = this.lineItemLists
        }
        this.selectInspectionItems = []
        this.showLine = true
      },
      async selectCheck() {
        if (this.checkeds) {
          this.lineTags = []
          const res = await Hapi.modifyLines({
            id: JSON.parse(sessionStorage.getItem('userInfo')).id,
            productionLineIdList: []
          })
          if (res) {
            await this.getAllLines()
            this.getAlls()
          }
        }
      },
      fixData(val) {
        if (val) {
          return this.fixNum(((val / this.orderInfo.doingCount) * 100).toFixed(2))
        }
      },
      fixNum(val) {
        const number = String(val).replace(/^(.*\..{2}).*$/, '$1')
        return Number(number)
      },
      lineSetData(res) {
        this.chartDataLine.columns = ['day', '数量']
        this.chartDataLine.rows = res.map(item => {
          return {
            ...item,
            '数量': item.quantity
          }
        })
        this.loading = false
      },
      pieSetDataWorkOrder(res) {
        this.chartDataWorkOrder.columns = ['name', 'value']
        const data = [{
          name: '延期',
          value: res.delayCount
        },
          {
            name: '3天后延期',
            value: res.delayCountFor3days
          }]
        this.chartDataWorkOrder.rows = data
        this.loading = false
      },
      pieSetDataWorkStatus(res) {
        this.chartDataWorkStatus.columns = ['name', 'value']
        const data = []
        for (const i in res.doingMap) {
          data.push({
            name: +i === 0 ? '未开始' : '进行中',
            value: res.doingMap[i]
          })
        }
        this.chartDataWorkStatus.rows = data
        this.loading = false
      },
      pieSetDataOrder(res) {
        this.chartDataOrder.columns = ['name', 'value']
        const data = [{
          name: '延期',
          value: res.delayCount
        },
          {
            name: '3天后延期',
            value: res.delayCountFor3days
          }]
        this.chartDataOrder.rows = data
        this.loading = false
      },
      pieSetData(res) {
        this.chartData.columns = ['name', 'value']
        const data = []
        for (const i in res.doingMap) {
          data.push({
            name: +i === 0 ? '待质检' : '质检中',
            value: res.doingMap[i]
          })
        }
        this.chartData.rows = data
        this.loading = false
      },
      // 工单状态
      async getWorkOrderStatus() {
        this.loading = true
        const res = await Hapi.getWorkOrderStatus({
          lineIdList: this.lineTags ? this.lineTags.map(item => item.id) : []
        })
        if (res) {
          this.pieSetDataWorkStatus(res)
          this.pieSetDataWorkOrder(res)
        }
      },
      // 质检数量
      async getInspectionNum() {
        this.loading = true
        const res = await Hapi.getInspectionNum()
        if (res) {
          this.pieSetData(res)
        }
      },
      // 订单
      async getOrder() {
        const res = await Hapi.getOrderTotal()
        if (res) {
          this.orderInfo = res
          this.allOrderNums[0].count = res.doneCountToday
          this.allOrderNums[1].count = res.doneCountWeek
          this.allOrderNums[2].count = res.doneCountMonth
          this.allOrderNums[3].count = res.doneCountQuarter
          this.allOrderNums[4].count = res.doneCountYear
          this.allStatus[0].count = res.doingMap[0]
          this.allStatus[1].count = res.doingMap[3]
          this.allStatus[2].count = res.doingMap[1]
          this.pieSetDataOrder(res)
        }
      },
      // 生产数量
      async getProductionNum() {
        const res = await Hapi.getProductNum(
          Object.assign({}, this.startEndDate, {
            lineIdList: this.lineTags ? this.lineTags.map(item => item.id) : []
          }))
        if (res) {
          this.productInfo = res
        }
      },
      // 完工数量
      async getFinishNums() {
        this.loading = true
        const res = await Hapi.getFinishNum(
          Object.assign({}, this.sevenStartEndDate, {
            lineIdList: this.lineTags ? this.lineTags.map(item => item.id) : []
          }))
        if (res) {
          this.lineSetData(res)
        }
      },
      // 首检和质检
      async getAllInspectNums(val) {
        const data = Object.assign({}, this.startEndDate, {
          inspectionType: val
        })
        const res = await Hapi.getAllInspectNum(data)
        if (res) {
          if (val === 0) {
            this.inspectInfo = res
            return false
          }
          this.inspectInfos = res
        }
      },
      // 工单列表
      async getTableData() {
        const search = [{
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }]
        const list = this.lineTags ? this.lineTags.map(item => item.id) : []
        const res = await api.getWorkData({
          searchVal: Encrypt(JSON.stringify([...search])),
          lineIdList: list
        })
        if (res) {
          const data = res.map(item => ({
              ...item,
              actualStartTime: item.producePlan.actualStartTime
            })
          )
          this.list = data
        }
      },
      radioChange(val) {
        if (this.params.radio[val] === 'last') {
          this.startEndDate = {
            beginTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
          }
        }
        if (this.params.radio[val] === 'week') {
          if (dayjs().day() === 0) {
            this.startEndDate = {
              beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
              endTime: dayjs().format('YYYY-MM-DD')
            }
          } else {
            this.startEndDate = {
              beginTime: dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD'),
              endTime: dayjs().endOf('week').add(1, 'day').format('YYYY-MM-DD')
            }
          }
        }
        if (this.params.radio[val] === 'month') {
          this.startEndDate = {
            beginTime: dayjs().startOf('month').format('YYYY-MM-DD'),
            endTime: dayjs().endOf('month').format('YYYY-MM-DD')
          }
        }
        if (this.params.radio[val] === 'year') {
          this.startEndDate = {
            beginTime: dayjs().startOf('year').format('YYYY-MM-DD'),
            endTime: dayjs().endOf('year').format('YYYY-MM-DD')
          }
        }
        if (val === 0) {
          this.getProductionNum()
          return
        }
        if (val === 1) {
          this.getAllInspectNums(0)
          return
        }
        if (val === 2) {
          this.getAllInspectNums(1)
          return
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .home-containter {
    width: 256px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .img {
      width: 256px;
      height: 201px;
      display: block;
    }
    .home-font {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      line-height: 20px;
      margin-top: 20px;
      display: inline-block;
    }
  }

  ::v-deep .m-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    padding: 5px 15px;
  }
  ::v-deep .m-radio-groups .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    padding: 5px 8px;
  }
  ::v-deep {
    .m-radio-group .el-radio-button__inner {
      min-width: 70px;
    }
    .m-radio-group .el-radio-button {
      min-width: 80px;
    }
    .m-radio-groups .el-radio-button__inner {
      min-width: 50px;
      padding: 12px 10px;
    }
    .m-radio-groups .el-radio-button {
      min-width: 60px;
    }
    .app-main {
      padding-top: 0 !important;
    }
  }
  .all-lines {
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    // align-items: center;
    .checkAll {
      margin-right: 20px;
      margin-bottom: 10px;
    }
    .tag {
      height: 22px;
      padding: 0px 10px;
      border-radius: 4px;
      margin-right: 10px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 22px;
      border-radius: 11px;
      border: 1px solid #607FFF;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        .el-button:first-child,
        .el-button:last-child {
          display: block;
        }
      }

      .el-button:first-child {
        color: #9597AE;
        padding: 0 3px;
        display: none;
        position: relative;
        right: 5px;
      }

      .el-button:last-child {
        color: #000000;
        padding: 0 3px;
        display: none;
        position: relative;
        left: 5px;
      }
    }

  }
  .all-title {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #41456B;
    line-height: 25px;
    margin-bottom: 12px;
    width: 100%;
  }
  .common-pad {
    padding: 20px;
    border-radius: 4px;
  }
  .common-tit {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .tit {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4B4F77;
      line-height: 32px;
    }
    .m-radio-group {
      height: 32px;
    }
    .m-radio-groupss {
      height: 32px;
      width: 280px;
    }
  }
  .m-radio-groups {
    height: 32px;
    width: 250px;
  }
  .container-top {
    height: 290px;
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    .order-left {
      flex: 1;
      border-right: 1px solid #DDE3EB;
      padding-right: 30px;
      .left-tit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        height: 32px;
        .title {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #41456B;
        }
        .des {
          padding: 0 20px;
          background: rgba(0, 171, 41, .1);
          border-radius: 4px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #00BC35;
        }
      }
      .left-cen {
        border-radius: 4px;
        .cen-li {
          height: 42px;
          display: flex;
          justify-content: space-between;
          background: #ECEEF2;
          align-items: center;
          border-bottom: 1px solid #DDE3EB;
          &:first-child {
            border-radius: 4px 4px 0 0;
            .tip {
              border-radius: 4px 0 0 0;
            }
          }
          &:last-child {
            border-bottom: none;
            border-radius: 0 0 4px 4px;
            .tip {
              border-radius: 0 0 0 4px;
            }
          }
          .tip {
            width: 7px;
            height: 42px;
            background: #F7B500;
            margin-right: 16px;
          }
          .tit {
            flex: 2;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4B4F77;
          }
          .desc {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #F7B500;
            padding-right: 20px;
          }
        }
      }
      .left-bot {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 22px;
        .tip {
          color: #F7B500;
          font-size: 10px;
          div {
            height: 20px;
          }
          span {
            display: inline-block;
            height: 23px;
            background: #F7B500;
            width: 100%;
          }
        }
        .tit {
          color: #607FFF;
          font-size: 10px;
          // word-break: break-all;
          div {
            height: 20px;
          }
          span {
            display: inline-block;
            height: 23px;
            background: #607FFF;
            width: 100%;
          }
        }
        .desc {
          color: #32C5FF;
          font-size: 10px;
          div {
            height: 20px;
          }
          span {
            display: inline-block;
            height: 23px;
            background: #32C5FF;
            width: 100%;
          }
        }
      }
    }
    .order-cen {
      flex: 1;
      border-right: 1px solid #DDE3EB;
      padding-left: 15px;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .order-right {
      flex: 1;
      margin-left: 20px;
      .right-tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 25px;
        margin-bottom: 12px;
      }
      .right-bot {
        height: 34px;
        background: #ECEEF2;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        margin-bottom: 5px;
        &:nth-child(even) {
          background: #EBEEFA;
        }
        .tip {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #545982;
        }
        .des {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #393D60;
        }
      }
    }
  }
  .container-bottom {
    height: 425px;
    display: flex;
    margin: 20px 0;
    padding: 0;
    .product-right {
      flex: 1;
      width: 48%;
      padding: 10px;
      background: #FFFFFF;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .product-left {
      flex: 1;
      width: 48%;
      padding: 10px;
      margin-right: 20px;
      background: #FFFFFF;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
  }
  .container-center {
    margin: 20px 0;
    display: flex;
    padding: 0;
    .work-left {
      background: #fff;
      flex: 1;
      width: 32%;
      padding: 10px;
      .left-bot {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-top: 15px;
        word-break: break-all;
        .bot-li {
          width: 30.5%;
          height: 93px;
          background: #F6F7F9;
          border-radius: 4px;
          border: 1px solid #DDE3EB;
          padding-right: 10px;
          margin: 5px;
          position: relative;
          .tip {
            position: absolute;
            top: 7px;
            left: 7px;
            width: 32px;
            height: 32px;
            display: block;
          }
          // &:first-child {
          //   margin-left: 0;
          // }
          // &:last-child {
          //   margin-right: 0;
          // }
          .tit {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393D60;
            text-align: right;
            padding-top: 16px;
            line-height: 20px;
          }
          .count {
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #41456B;
            text-align: right;
            margin-top: 5px;
            line-height: 22px;
          }
        }
      }
    }
    .work-center {
      flex: 1;
      width: 32%;
      margin: 0 20px;
      padding: 10px;
      background: #fff;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .work-right {
      flex: 1;
      width: 32%;
      padding: 10px;
      background: #fff;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
  }
  .container-footer {
    display: flex;
    min-height: 283px;
    padding: 0;
    .inspection-left {
      width: 32%;
      background: #fff;
      padding: 15px;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .inspection-center {
      width: 32.4%;
      margin: 0 20px;
      padding: 10px;
      background: #fff;
    }
    .inspection-right {
      width: 32%;
      background: #fff;
      padding: 10px;
    }
    .left-bot {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-top: 25px;
      .bot-li {
        flex: 1;
        height: 174px;
        background: #F6F7F9;
        border-radius: 4px;
        border: 1px solid #DDE3EB;
        padding-right: 10px;
        margin: 5px;
        position: relative;
        .tip {
          position: absolute;
          top: 7px;
          left: 7px;
          width: 32px;
          height: 32px;
          display: block;
        }
        // &:first-child {
        //   margin-left: 0;
        // }
        // &:last-child {
        //   margin-right: 0;
        // }
        .tit {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393D60;
          text-align: right;
          padding-top: 60px;
          line-height: 20px;
        }
        .count {
          height: 33px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #41456B;
          text-align: right;
          line-height: 33px;
        }
      }
    }
  }
</style>
