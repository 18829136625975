// 工单列表
export const columnsAll = [
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    hiddenSearch: true,
    sortable: false
  }
]
