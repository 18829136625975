var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "call-search-wrap" },
    [
      _c(
        "el-upload",
        _vm._b(
          {
            ref: "upload",
            staticClass: "upload-demo",
            attrs: {
              drag: "",
              multiple: "",
              "file-list": _vm.fileList,
              accept: ".mp4"
            }
          },
          "el-upload",
          _vm.uploadProp,
          false
        ),
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("将文件拖到此处，或"),
            _c("em", [_vm._v("点击上传")])
          ]),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip"
            },
            [_vm._v("只能上传MP4文件")]
          )
        ]
      ),
      _c(
        "el-button",
        {
          staticClass: "e-btn",
          attrs: { size: "small", type: "primary" },
          on: { click: _vm.selectadd }
        },
        [_vm._v("合并下载")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }